// breadcrumbs
.breadcrumb{
    list-style:none;
    display: inline-block;
    position: fixed;
    padding: 0;
    margin-left: -5px;
    z-index: 900;
    top: 65px;
    background:transparent;
    
    @include themify($themes) {
      color: themed('colorText');
    }
    
    .icon{
      font-size: 12px;
    }
    
    li{
      float:left;
      a{
        color:#FFF;
        display:block;
        
        background: $color-breadcrumb-base;
        
        text-decoration: none;
        position:relative;
        height: 20px;
        line-height:20px;
        padding: 0 5px 0 3px;
        text-align: center;
        margin-right: 12px;
      }
      &:nth-child(even){
        a{
          background-color: $color-breadcrumb-base;        
          &:before{ 
            border-color: $color-breadcrumb-base;
            border-left-color:transparent;
           } 
          &:after{
            border-left-color: $color-breadcrumb-base;
          }
        }
      }
      &:first-child{
        a{
          padding-left:10px;
          border-radius: (4px 0 0 4px);
          &:before{
            border:none;
           }
        }
      }
      &:last-child{
        a{
          padding-right:15px;
          border-radius: (0 4px 4px 0);
          &:after{
            border:none;
           }
        }
      }
      
      a{   
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid $color-breadcrumb-base;
          border-width:10px 5px;
          width: 0;
          height: 0;
        }
        &:before{
          left:-10px;
          border-left-color:transparent;
        }
        &:after{
          left:100%;
          border-color:transparent;
          border-left-color: $color-breadcrumb-base;
        }
        &:hover{
          background-color: $color-breadcrumb-hover;
          
          &:before{
            border-color:$color-breadcrumb-hover;
            border-left-color:transparent;
           }
          &:after{
            border-left-color:$color-breadcrumb-hover;
          }
        }
        &:active{
          background-color: $color-breadcrumb-hover;
          
          &:before{
            border-color:$color-breadcrumb-hover;
            border-left-color:transparent;
           }
          &:after{
            border-left-color:$color-breadcrumb-hover;
          }
        }
      }
    }
  }
  